<template>
  <div class="about">
    <v-header></v-header>
    <div class="container">
      <div class="logo">
        <img class="logo-img" src="https://cdn.001ppt.cn/h5/assets/images/logo.png">
        <span class="version">www.001ppt.com</span>
      </div>

      <van-cell-group class="center_cells" :border="false">
        <van-cell title="关于我们" title-style="font-weight: bold;" is-link :border="false" to="/about/us"></van-cell>
        <van-cell title="版权声明" title-style="font-weight: bold;" is-link :border="false"
                  to="/about/copyright"></van-cell>
        <van-cell title="用户协议" title-style="font-weight: bold;" is-link :border="false"
                  to="/about/agreement"></van-cell>
        <van-cell title="上传协议" title-style="font-weight: bold;" is-link :border="false"
                  to="/about/upload"></van-cell>
        <van-cell title="会员条款" title-style="font-weight: bold;" is-link :border="false" to="/about/vip"></van-cell>
        <van-cell title="常见问题" title-style="font-weight: bold;" is-link :border="false"
                  to="/about/question"></van-cell>
        <van-cell title="上传规范" title-style="font-weight: bold;" is-link :border="false"
                  to="/about/standard"></van-cell>
      </van-cell-group>
    </div>


    <div class="copyright">
      <span>灵感严选（广州）科技有限公司 版权所有</span>
      <span>© 2018-{{ currYear }} 001PPT.COM 粤ICP备2021005746号</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  computed:{
    currYear(){
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped lang="less">
.about {
  .container {
    padding-top: var(--header-height);
    height: calc(100vh - var(--header-height));

    .logo {
      padding: 44px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo-img {
        width: 168px;
      }

      .version {
        font-weight: bold;
        margin-top: 8px;
      }
    }

    .center_cells {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      background-color: #fff;
    }
  }


  .copyright {
    position: fixed;
    bottom: 44px;
    left: 28px;
    right: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      color: rgba(21, 29, 54, .5);
      font-size: 12px;
      line-height: 150%;
    }
  }

}
</style>
