<template>
  <div class="home-list home-plans">
    <home-plans-search :header-height="headerHeight" :query="query" ref="homePlanSearch" @addKeyword="addKeyword"
                       @removeKeyword="removeKeyword" @setKeyword="setKeyword"
                       @searchBrank="onSearchBrank"
                       @setKeywordAndExactOnly="setKeywordAndExactOnly"/>

    <template v-if="!hasQuery">
      <!--    banner-->
      <home-banner/>
      <!--    金刚区-->
      <home-quick-link/>
    </template>


    <van-sticky :offset-top="headerHeight">
      <div id="planSticky">
        <van-dropdown-menu active-color="#151d36" ref="dropdownMenu">
          <van-dropdown-item :title="query.freeTypes?'已筛选':'全部方案'" ref="freeTypesDropdownItem"
                             @open="onDropdownItemOpen">
            <div class="free-types">
              <div class="cell">
                <div class="cell-title">免费方案</div>
                <div class="cell-value" @click="toggleFreeTypes('1')">
                  <van-switch size="20px" :value="query.freeTypes.includes('1')" active-color="#151d36"/>
                </div>
              </div>
              <div class="cell">
                <div class="cell-title">会员免费</div>
                <div class="cell-value" @click="toggleFreeTypes('2')">
                  <van-switch size="20px" :value="query.freeTypes.includes('2')" active-color="#151d36"/>
                </div>
              </div>
              <div class="cell">
                <div class="cell-title">会员折扣</div>
                <div class="cell-value" @click="toggleFreeTypes('3')">
                  <van-switch size="20px" :value="query.freeTypes.includes('3')" active-color="#151d36"/>
                </div>
              </div>
              <div class="free-types-btn confirm" @click="onChangeQuery($refs.freeTypesDropdownItem)">确认</div>
            </div>
          </van-dropdown-item>
          <van-dropdown-item @change="onChangeQuery" v-model="query.orderBy" :options="options.orderBy"
                             @open="onDropdownItemOpen"/>
          <van-dropdown-item ref="categoryDropdownItem" @open="onDropdownItemOpen">
            <template #title>
              <div class="dropdown-title" :class="{dot: hasFilter}">快速找案</div>
            </template>
            <div>
              <div style="display: flex;">
                <div style="flex-shrink: 0;background: #f7f8fa">
                  <van-sidebar v-model="selected.topPlanCategoryIndex">
                    <van-sidebar-item v-for="topCategory in categories" :key="topCategory.id" :title="topCategory.name"
                                      :dot="!!query[getCategoryKey(topCategory)]"/>
                    <van-sidebar-item title="个性筛选" :dot="hasPersonalFilter"/>
                  </van-sidebar>
                </div>
                <div class="filter-panel">
                  <template v-if="topCategory && selected.topPlanCategoryIndex<4">
                    <div class="category-item" v-for="item in topCategory.subPlanCategory" :key="item.id"
                         v-if="item.name!=='不限'">
                      <div class="category-item-name" :class="{selected:isSelectedCategory(topCategory,item)}"
                           @click="toggleCategory(topCategory,item)">
                        <div>{{ item.name }}</div>
                        <div class="check-icon">
                          <van-checkbox shape="square" checked-color="#151d36"
                                        :value="isSelectedCategory(topCategory,item)"
                                        @change="toggleCategory(toggleCategory,item)"/>
                        </div>
                      </div>
                      <div class="sub-categories">
                        <div class="sub-category-item" v-if="subItem.name!=='不限'" :key="subItem.id"
                             :class="{selected:isSelectedCategory(topCategory,subItem)}"
                             @click="toggleCategory(topCategory,subItem)"
                             v-for="subItem in item.subPlanCategory">{{ subItem.name }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div style="padding-top: 24px;font-size: 12px;">
                      <van-checkbox style="margin-bottom: 12px" v-model="query.isRecommended" shape="square"
                                    icon-size="18px" checked-color="#151d36">严审推荐
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.isMyFocus" shape="square"
                                    icon-size="18px"
                                    checked-color="#151d36">我的关注
                      </van-checkbox>
                      <!--                      <van-checkbox style="margin-bottom: 12px" v-model="query.isHasPlace" shape="square"-->
                      <!--                                    icon-size="18px"-->
                      <!--                                    checked-color="#151d36">包含场地-->
                      <!--                      </van-checkbox>-->
                      <!--                      <van-checkbox style="margin-bottom: 12px" v-model="query.isHasBrank" shape="square"-->
                      <!--                                    icon-size="18px"-->
                      <!--                                    checked-color="#151d36">包含品牌-->
                      <!--                      </van-checkbox>-->
                      <van-checkbox style="margin-bottom: 12px" v-model="query.isInCollection" shape="square"
                                    icon-size="18px" checked-color="#151d36">合集收录
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.isInDiscount" shape="square"
                                    icon-size="18px"
                                    checked-color="#151d36">限时折扣
                      </van-checkbox>

                      <van-checkbox style="margin-bottom: 12px" v-model="query.brokerTag" shape="square"
                                    @change="onToggleQuery('brokerTag',$event)"
                                    icon-size="18px"
                                    checked-color="#151d36">只看券商
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.noBrokerTag" shape="square"
                                    @change="onToggleQuery('noBrokerTag',$event)"
                                    icon-size="18px"
                                    checked-color="#151d36">不看券商
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.foreignTag" shape="square"
                                    @change="onToggleQuery('foreignTag',$event)"
                                    icon-size="18px"
                                    checked-color="#151d36">只看外文
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.noForeignTag" shape="square"
                                    @change="onToggleQuery('noForeignTag',$event)"
                                    icon-size="18px"
                                    checked-color="#151d36">不看外文
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.yearTopTag" shape="square"
                                    icon-size="18px"
                                    checked-color="#151d36">年度TOP
                      </van-checkbox>
                      <van-checkbox style="margin-bottom: 12px" v-model="query.down100Tag" shape="square"
                                    icon-size="18px"
                                    checked-color="#151d36">百次下载
                      </van-checkbox>

                    </div>
                  </template>
                </div>
              </div>
              <div class="category-btns">
                <div class="category-btn cancel" @click="clearQuery">重置</div>
                <div class="category-btn confirm" @click="onChangeQuery($refs.categoryDropdownItem)">确认</div>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>

        <template v-if="query.keyword || hasFilter || hasHighLevelFilter">
          <div
            style="display: flex;align-items: center;justify-content: space-between;padding: 12px 27px 0 27px;background: #fff">
            <div class="keywords-option" style="flex-wrap: wrap">
              <van-checkbox icon-size="14px" shape="square" checked-color="#151d36" v-model="query.freeFirst"
                            style="flex-shrink: 0" @change="onChangeQuery(null, 'freeFirst')">
                <div style="font-size: 12px;display: flex;align-items: center;transform: translateX(-4px);margin-right: 2px">
                  免费优先
                </div>
              </van-checkbox>
              <van-checkbox icon-size="14px" shape="square" checked-color="#151d36" v-model="query.exactOnly"
                            style="flex-shrink: 0"
                            @change="onChangeQuery">
                <div style="font-size: 12px;display: flex;align-items: center;transform: translateX(-4px);margin-right: 2px">
                  精准搜索
                  <img style="height: 16px" src="https://lgyx-static.oss-cn-guangzhou.aliyuncs.com/h5/img/vip.svg"
                       alt="">
                </div>
              </van-checkbox>
              <van-checkbox icon-size="14px" shape="square" checked-color="#151d36" v-model="query.isFullTextSearch"
                            style="flex-shrink: 0"
                            @change="onChangeQuery">
                <div style="font-size: 12px;display: flex;align-items: center;transform: translateX(-4px);margin-right: 2px">
                  全文搜索
                </div>
              </van-checkbox>
              <van-checkbox icon-size="14px" shape="square" checked-color="#151d36" v-model="query.pptOnly"
                            style="flex-shrink: 0"
                            @change="onChangeQuery">
                <div style="font-size: 12px;display: flex;align-items: center;transform: translateX(-4px);">
                  只搜PPT
                  <img style="height: 16px" src="https://lgyx-static.oss-cn-guangzhou.aliyuncs.com/h5/img/vip.svg"
                       alt="">
                </div>
              </van-checkbox>
            </div>
            <div>
              <van-icon name="arrow-up" v-if="visibles.keywords" @click="visibles.keywords=false" style="transform: translateY(2px)"/>
              <van-icon name="arrow-down" v-else @click="visibles.keywords=true" style="transform: translateY(2px)"/>
            </div>
          </div>
          <div class="keywords" v-if="visibles.keywords">
            <template>
              <div class="keyword" v-if="query.planType">
                {{ query.planType }}
                <van-icon @click="removeQuery('planType')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.activityType">
                {{ query.activityType }}
                <van-icon @click="removeQuery('activityType')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.industryType">
                {{ query.industryType }}
                <van-icon @click="removeQuery('industryType')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.placeType">
                {{ query.placeType }}
                <van-icon @click="removeQuery('placeType')" name="cross" style="margin-left: 4px"/>
              </div>

              <div class="keyword" v-if="!item.renderIgnore && query[item.key] && (query[item.key] + '')!=='false'"
                   v-for="item in highLevelQueryOptions">
                {{ item.label }}: {{ query[item.key] }}
                <van-icon @click="removeQuery(item.key)" name="cross" style="margin-left: 4px"/>
              </div>

              <div class="keyword" v-if="query.minFileSize || query.maxFileSize">
                文件大小:
                <template v-if="query.minFileSize">{{ query.minFileSize }}MB</template>
                <template v-else>不限</template>
                ~
                <template v-if="query.maxFileSize">{{ query.maxFileSize }}MB</template>
                <template v-else>不限</template>
                <van-icon @click="removeQuery(['minFileSize','maxFileSize'])" name="cross" style="margin-left: 4px"/>
              </div>

              <div class="keyword" v-if="query.minPageTotal || query.maxPageTotal">
                <template v-if="query.minPageTotal">{{ query.minPageTotal }}页</template>
                <template v-else>不限</template>
                ~
                <template v-if="query.maxPageTotal">{{ query.maxPageTotal }}页</template>
                <template v-else>不限</template>
                <van-icon @click="removeQuery(['minPageTotal','minPageTotal'])" name="cross" style="margin-left: 4px"/>
              </div>

              <div class="keyword" v-if="query.isRecommended">
                严审推荐
                <van-icon @click="removeQuery('isRecommended')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.isMyFocus">
                我的关注
                <van-icon @click="removeQuery('isMyFocus')" name="cross" style="margin-left: 4px"/>
              </div>
              <!--              <div class="keyword" v-if="query.isHasPlace">-->
              <!--                包含场地-->
              <!--                <van-icon @click="removeQuery('isHasPlace')" name="cross" style="margin-left: 4px"/>-->
              <!--              </div>-->
              <!--              <div class="keyword" v-if="query.isHasBrank">-->
              <!--                包含品牌-->
              <!--                <van-icon @click="removeQuery('isHasBrank')" name="cross" style="margin-left: 4px"/>-->
              <!--              </div>-->
              <div class="keyword" v-if="query.isInCollection">
                合集收录
                <van-icon @click="removeQuery('isInCollection')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.isInDiscount">
                限时折扣
                <van-icon @click="removeQuery('isInDiscount')" name="cross" style="margin-left: 4px"/>
              </div>

              <div class="keyword" v-if="query.brokerTag">
                只看券商
                <van-icon @click="removeQuery('brokerTag')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.noBrokerTag">
                不看券商
                <van-icon @click="removeQuery('noBrokerTag')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.foreignTag">
                只看外文
                <van-icon @click="removeQuery('foreignTag')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.noForeignTag">
                不看外文
                <van-icon @click="removeQuery('noForeignTag')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.yearTopTag">
                年度TOP
                <van-icon @click="removeQuery('yearTopTag')" name="cross" style="margin-left: 4px"/>
              </div>
              <div class="keyword" v-if="query.down100Tag">
                百次下载
                <van-icon @click="removeQuery('down100Tag')" name="cross" style="margin-left: 4px"/>
              </div>


              <div class="keyword" v-for="(item,index) in keywordArr" :key="index">{{ item }}
                <van-icon @click="removeKeyword(item)" name="cross" style="margin-left: 4px"/>
              </div>

              <div style="margin: 0 0 4px 8px;display: flex;align-items: center;font-size: 12px;flex-shrink: 0"
                   @click="clearQuery(true)">
                清空
                <van-icon name="cross" style="margin-left: 4px"/>
              </div>
              <img @click="copyLink" style="height: 18px;width: 18px;transform: translateY(4px);margin-left: 4px"
                   src="https://lgyx-static.oss-cn-guangzhou.aliyuncs.com/h5/img/link.svg" alt="">
            </template>
          </div>
          <div class="keywords" v-else></div>
          <div v-if="visibles.keywords" style="background: #fff;padding: 0 0 12px 0">
            <div class="ai-keywords" v-if="aiKeywords && aiKeywords.length" :class="{'ai-keywords-any-checked': aiKeywords.some(k=>query.keyword.split(',').includes(k))}">
              <img class="ai-icon" src="https://cdn.001ppt.cn/pc2/ai/knowledge/ai-icon.png" alt="">
              <div class="ai-keywords-items">
                <div class="ai-keyword-item" v-for="(item,index) in aiKeywords" :key="index" @click="toggleAiKeyword(item)">
                  <div class="checkbox" :class="{checked: (query.keyword||'').split(',').includes(item) }">
                    <van-icon name="success" style="transform: translateY(1px)"
                              v-if="(query.keyword||'').split(',').includes(item)"/>
                  </div>
                  {{ item }}
                </div>
              </div>
              <div class="ai-keywords-clear" @click="aiKeywords=[]">
                <van-icon name="cross"/>
              </div>
            </div>
          </div>
        </template>
      </div>
    </van-sticky>


    <van-pull-refresh v-model="refreshing" @refresh="loadPageData(1,true,400)" loading-text="灵感加载中">
      <div class="ref-plan-lists" v-if="query.keyword && refPlanLists && refPlanLists.length">
          <div class="ref-plan-list-item" @click="$router.push(`/casePlanDetail/${item.id}`)"
               v-for="(item,index) in refPlanLists" :key="index" v-if="index<5">
            {{ item.shortTitle || item.title }}({{ item.planCount }}份)
          </div>
      </div>

      <template>
        <div v-if="refreshing" style="min-height: 100vh">
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
        </div>

        <div class="scroll-empty" v-if="pageData.total<=0 && !refreshing">
          <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
          <div style="font-size: 14px;color: #999;padding-top: 16px">噢！这可能有点儿空 (⊙v⊙)</div>
        </div>

        <van-list v-show="pageData.total>0"
                  offset="400"
                  :immediate-check="false"
                  :finished="!hasNextPage"
                  finished-text="灵感到底了~"
                  v-model="loading"
                  @load="loadPageData(pageData.current+1,false)">
          <div class="case-container">
            <div class="home-plan-item" v-for="(item,index) in pageData.records" :key="index">
              <div class="home-plan-item-ground-glass" v-if="groundGlass" @click.stop="goDetail(item)"></div>
              <plan-card :stop-tag-click="true" @handle-click="goDetail(item)" :detail.sync="item"
                         @clickTag="setKeyword" search/>
            </div>
          </div>

        </van-list>
      </template>
    </van-pull-refresh>


    <div v-show="visibles.pagerClose">
      <div class="pager" v-if="scrolling">
        <div class="pager-close" @click="visibles.pagerClose=false">
          <van-icon name="cross" size="12px" color="#fff"></van-icon>
        </div>
        <div class="pager-curr">
          {{ currCardIndex > 999 ? '999+' : (currCardIndex > pageData.total ? pageData.total : currCardIndex) }}
        </div>
        <div class="pager-total">{{ pageData.total >= 999 ? '999+' : pageData.total }}</div>
      </div>
      <div class="pager" v-else @click="toTop" style="font-size: 20px">
        <div class="pager-close" @click="visibles.pagerClose=false">
          <van-icon name="cross" size="12px" color="#fff"></van-icon>
        </div>
        <van-icon name="back-top"/>
      </div>
    </div>
  </div>


</template>

<script>
import {scheme, user} from '@/api'
import {copyToClipBoard, objToQueryString, sleep} from "../../../config/util";
import {homeSearchV2Api} from "../../../api/v2/homeSearchV2Api";
import vCase from '@/components/case'
import {scrollToDropdownMenu} from "./home";
import {nav} from "../../../config/nav";
import {mapGetters, mapMutations} from 'vuex'
import {redirectV2Api} from "../../../api/v2/redirectV2Api";
import HomePlansSearch from "./homePlansSearch";
import HomeQuickLink from "./homeQuickLink";
import HomeBanner from "./homeBanner";
import PlanCard from "../../../components/planCard";
import {keywordApi} from "../../../api/v2/keywordApi";
import Toast from "../../../components/toast";


export default {
  props: ['headerHeight', 'currTab'],
  components: {PlanCard, HomeQuickLink, HomePlansSearch, vCase, HomeBanner},
  computed: {
    ...mapGetters(['userInfo', 'userVip']),
    topCategory() {
      return this.categories[this.selected.topPlanCategoryIndex]
    },

    groundGlass(){
      const {minAuditTime,maxAuditTime,down100Tag} = this.query
      if (minAuditTime === '2024-01-01' && maxAuditTime === '2024-12-31' && down100Tag && this.userVip.vipRightId === '3') {
        return true
      }
    },


    hasQuery() {
      return Object.keys(this.query).some(k =>
        k !== 'freeFirst' &&
        k !== 'tab' &&
        k !== 'orderBy' &&
        this.query[k]
      )
    },


    hasNextPage() {
      const {total, current, pageSize} = this.pageData;
      return total > (current * pageSize);
    },


    hasFilter() {
      const {planType, industryType, placeType, activityType} = this.query
      return planType || activityType || industryType || planType || this.hasPersonalFilter
    },


    // 是否有个性筛选
    hasPersonalFilter() {
      const {
        isInDiscount,
        isInCollection,
        isHasBrank,
        isHasPlace,
        isMyFocus,
        isRecommended,
        down100Tag,
        brokerTag,
        yearTopTag,
        foreignTag,
        noBrokerTag,
        noForeignTag
      } = this.query
      return !!isInDiscount || !!isInCollection || !!isHasBrank || !!isHasPlace || !!isMyFocus || !!isRecommended || !!down100Tag || !!yearTopTag || !!brokerTag || !!foreignTag || !!noForeignTag || !!noBrokerTag
    },


    hasHighLevelFilter() {
      return this.highLevelQueryOptions.some(o => {
        const key = o.key
        const value = this.query[key]
        return !!value & (value + '') !== 'false'
      })
    },


    keywordArr() {
      if (this.query.keyword) {
        return this.query.keyword.split(',').filter(k => k && k.length)
      }
      return []
    },


    options() {
      const orderBy = [
        {value: '', text: '严选排序'},
        {value: 'updateTime', text: '最新严审'},
        {value: 'viewTimes', text: '浏览最多'},
        {value: 'recommendTimes', text: '推荐最多'},
        {value: 'collectTimes', text: '收藏最多'},
        {value: 'random', text: '随便看看'},
        {value: 'hotScore', text: '热度最高'},
      ]
      if (this.query.keyword) {
        orderBy.push({value: 'keywordRefScore', text: '匹配度最高'})
      }

      return {
        orderBy
      }
    }

  },

  data() {
    return {
      currCardIndex: 1,
      scrolling: false,
      hasInit: false,
      categories: [],
      refPlanLists: [],
      selected: {
        topPlanCategoryIndex: 0
      },
      visibles: {
        keywords: true,
        pagerClose: true
      },
      highLevelQueryOptions: [
        {key: 'title', label: '标题'},
        {key: 'tag', label: '标签'},
        {key: 'creator', label: '作者'},
        {key: 'brank', label: '品牌'},
        {key: 'place', label: '场地'},
        {key: 'fileExt', label: '文件格式'},
        {key: 'recommendText', label: '推荐语'},
        {key: 'minFileSize', label: '最小文件大小', renderIgnore: true},
        {key: 'maxFileSize', label: '最大文件大小', renderIgnore: true},
        {key: 'minPageTotal', label: '标题', renderIgnore: true},
        {key: 'maxPageTotal', label: '标题', renderIgnore: true},
      ],

      aiKeywords: [],

      query: {
        isFullTextSearch: '',
        freeTypes: '',
        orderBy: '',
        isMyFocus: false,
        isHasPlace: false,
        isRecommended: false,
        isHasBrank: false,
        isInCollection: false,
        isInDiscount: false,
        planType: '',
        activityType: '',
        placeType: '',
        industryType: '',
        keyword: '',
        pptOnly: false,
        exactOnly: false,
        freeFirst: '',
        brokerTag: '',
        noBrokerTag: '',
        noForeignTag: '',
        foreignTag: '',
        yearTopTag: '',
        down100Tag: '',

        title: '',
        tag: '',
        creator: '',
        brank: '',
        place: '',
        fileExt: '',
        recommendText: '',
        minFileSize: '',
        maxFileSize: '',
        minPageTotal: '',
        maxPageTotal: ''
      },
      refreshing: false,
      loading: false,
      pageData: {
        current: 1,
        pageSize: 5,
        records: [],
        total: 0
      }
    }
  },


  watch: {
    'query.keyword': {
      immediate: true,
      async handler(val) {
        if (!val) {
          return
        }
        const arr = val.split(',')
        if (arr.some(k => this.aiKeywords.includes(k))) {
          return
        }
        let res = await keywordApi.refKeywords(val)
        res = res || ''
        this.aiKeywords = res.split(',').filter(k => k && k.length)
      }
    }
  },


  activated() {
    // if (this.currTab === 'plan') {
    document.querySelector('.home-plans').scrollTop = this.$store.state.scrollPosition.home.plan;
    if (!!this.$route.query.ts) {
      this.initQuery()
      this.loadPageData(1, true)
    }
    // }
  },


  mounted() {
    window.showHomeSearch = () => this.toggleSearch()
    document.querySelector('.home-plans').addEventListener('scroll', this.onScroll)
  },


  beforeDestroy() {
    window.showHomeSearch = null
    const elem = document.querySelector('.home-plans')
    if (elem) {
      removeEventListener('scroll', this.onScroll)
    }
  },


  methods: {

    toggleAiKeyword(keyword) {
      const keywordArr = (this.query.keyword||'').split(',').filter(k=>k&&k.length)
      let arr = [...keywordArr]
      if (arr.includes(keyword)) {
        arr = arr.filter(v => v !== keyword)
      } else {
        arr.push(keyword)
      }
      this.query.keyword = arr.join(',')
      this.onChangeQuery()

      // this.$store.commit('homePlan/UPDATE_QUERY', {keyword})
      // this.$store.dispatch('homePlan/updateQuery', {})
    },

    init() {
      if (!this.hasInit) {
        this.initQuery()
        this.loadCategories()
        this.loadPageData(1, true, 400)
      }
      this.hasInit = true
    },


    initQuery() {
      if (!this.$route.query.tab || this.$route.query.tab === 'plan') {
        for (const key in this.$route.query) {
          if (!key in this.query) {
            continue
          }

          let value = this.$route.query[key]
          if (!value) {
            continue
          }

          if (value === 'true') {
            value = true
          } else if (value === 'false') {
            value = false
          }
          this.query[key] = value
        }

        delete this.query.shareUserId
      }
    },


    onToggleQuery(key, value) {
      const reverseKeyMap = {
        'brokerTag': 'noBrokerTag',
        'foreignTag': 'noForeignTag',
        'noBrokerTag': 'brokerTag',
        'noForeignTag': 'foreignTag'
      }
      if (Object.keys(reverseKeyMap).includes(key) && value) {
        const rk = reverseKeyMap[key]
        this.query[rk] = ''
      }
    },


    // 加载分类
    async loadCategories() {
      const res = await scheme.allPlanCategories();
      this.categories = res.data.filter(c => ['按方案分', '按活动分', '按行业分'].includes(c.name));
    },


    // 切换免费类型
    toggleFreeTypes(t) {
      let freeTypes = this.query.freeTypes.split(',')
      if (freeTypes.includes(t)) {
        freeTypes = freeTypes.filter(f => f !== t)
      } else {
        freeTypes.push(t)
      }
      this.query.freeTypes = freeTypes.filter(f => f && f.length).join(',')
    },


    clearQuery(clearKeywords) {
      // this.query.planType = ''
      // this.query.activityType = ''
      // this.query.industryType = ''
      // this.query.placeType = ''
      // this.query.isRecommended = false
      // this.query.isMyFocus = false
      // this.query.isHasPlace = false
      // this.query.isInCollection = false
      // this.query.isInDiscount = false
      // this.query.isHasBrank = false
      // this.query.orderBy = ''
      for (const key in this.query) {
        if (key === 'keyword') {
          continue
        }
        this.query[key] = ''
      }

      if (clearKeywords) {
        this.query.keyword = ''
      }
      this.onChangeQuery(this.$refs.categoryDropdownItem)
    },


    async onChangeQuery(dropdownItem, key) {
      if (dropdownItem && dropdownItem.toggle) {
        dropdownItem.toggle()
      }

      this.refreshing = true
      await this.loadPageData(1, true, 500)
      await sleep(100)
      if (!this.$route.query.freeFirst && key !== 'freeFirst') {
        this.query.freeFirst = true
      }
      scrollToDropdownMenu(0, '.home-plans')
      const queryString = objToQueryString({
        ...this.query,
        tab: 'plan'
      })

      history.replaceState(null, null, '/home?' + queryString)
    },


    // 加载数据
    async loadPageData(current, clearRecords = false, minLoadingMs) {
      minLoadingMs = minLoadingMs || 0
      const sleepProm = sleep(minLoadingMs)
      if (clearRecords) {
        this.refreshing = true
      }
      try {
        const pageSize = this.pageData.pageSize;
        const params = {
          current, pageSize,
          size: pageSize,
          ...this.query,
        };
        for (const key of ['isMyFocus', 'isHasBrank', 'isInDiscount', 'isInCollection', 'isRecommended', 'isHasPlace']) {
          if (!params[key]) {
            delete params[key]
          }
        }

        const res = await homeSearchV2Api.search(params);
        this.pageData.current = res.page.current;
        this.pageData.pageSize = res.page.pageSize;
        this.pageData.total = res.page.total;
        if (clearRecords) {
          this.pageData.records = res.page.records;
        } else {
          this.pageData.records = [...this.pageData.records, ...res.page.records];
        }
        this.refPlanLists = res.planLists
      } finally {
        await sleepProm
        this.refreshing = false;
        this.loading = false;
        if (current === 1) {
          this.$emit('afterRefresh')
        }
      }
    },


    getCategoryKey(topCategory) {
      let key = ''
      switch (topCategory.name) {
        case '按方案分':
          key = 'planType';
          break;
        case '按活动分':
          key = 'activityType';
          break;
        case '场地类型':
          key = 'placeType';
          break;
        case '按行业分':
          key = 'industryType';
          break;
      }
      return key
    },


    // 切换分类选中
    toggleCategory(topCategory, item) {
      const key = this.getCategoryKey(topCategory)
      if (key) {
        this.query[key] = item.name === this.query[key] ? '' : item.name
      }
    },


    // 是否被选中
    isSelectedCategory(topCategory, item) {
      const key = this.getCategoryKey(topCategory)
      return key && this.query[key] === item.name
    },


    addKeyword(k) {
      if (this.keywordArr.includes(k)) {
        return
      }
      this.query.keyword = [...this.keywordArr, k].filter(i => i && i.length).join(',')
      if (this.query.keyword && !this.query.orderBy) {
        this.query.orderBy = 'keywordRefScore'
      }
      this.onChangeQuery()
    },


    setKeyword(k) {
      this.query.keyword = k
      if (this.query.keyword && !this.query.orderBy) {
        this.query.orderBy = 'keywordRefScore'
      } else if (!this.query.keyword && this.query.orderBy === 'keywordRefScore') {
        this.query.orderBy = ''
      }
      this.onChangeQuery()
    },


    setKeywordAndExactOnly(k) {
      this.query.exactOnly = true
      this.setKeyword(k)
    },


    // 移除关键字
    removeKeyword(item) {
      this.query.keyword = this.keywordArr.filter(k => k !== item).join(',')
      if (!this.query.keyword && this.query.orderBy === 'keywordRefScore') {
        this.query.orderBy = ''
      }
      this.onChangeQuery()
    },


    removeQuery(key) {
      if (Array.isArray(key)) {
        for (const k of key) {
          this.query[k] = ''
        }
      } else {
        this.query[key] = ''
      }
      this.onChangeQuery()
    },


    goDetail(item) {
      if(this.groundGlass){
        Toast.info('本榜单为VIP专享')
        return this.$router.push('/member')
      }
      const scrollTop = document.querySelector('.home-plans').scrollTop
      this.$store.commit('scrollPosition/SET_HOME_SCROLL', {plan: scrollTop})
      nav.goPlanDetail(item.plan.id)
    },


    // 复制链接
    copyLink() {
      this.$loading('生成链接中')
      try {
        const query = {
          ...this.query,
          tab: 'plan'
        }
        // if (Object.keys(this.userInfo).length) {
        //   query.shareUserId = this.userInfo.id
        // }
        // const queryString = objToQueryString(query)
        // const url = 'https://m.001ppt.com/home?' + queryString
        // const res = await redirectV2Api.create(url)
        // copyToClipBoard('https://www.001ppt.com/redirect?id=' + res)
        // this.$toast.success('复制成功')

        const freeTypeEnum = {
          free: 1,       // 免费方案
          vipFree: 2,    // 会员免费
          needPay: 3     // 会员折扣
        };

        let arr = [];
        const {keyword, placeType, industryType, activityType, planType, freeTypes} = query;
        console.log(query)

        if (keyword) {
          arr.push(`关键字包含${keyword}`);
        }
        if (placeType) {
          arr.push(`在${placeType}举办`)
        }
        if (industryType) {
          arr.push(`${industryType}行业`)
        }
        // let str = arr.join('的');
        let str = ''

        const exactSearchStrs = []
        if (query.title) {
          exactSearchStrs.push(`标题包含${query.title}`)
        }
        if (query.tag) {
          exactSearchStrs.push(`标签包含${query.tag}`)
        }
        if (query.creator) {
          exactSearchStrs.push(`作者为${query.creator}`)
        }
        if (query.brank) {
          exactSearchStrs.push(`品牌为${query.brank}`)
        }
        if (query.place) {
          exactSearchStrs.push(`场地为${query.place}`)
        }
        if (query.fileExt) {
          exactSearchStrs.push(`文件格式为${query.fileExt}`)
        }
        if (query.recommendText) {
          exactSearchStrs.push(`推荐语包含${query.recommendText}`)
        }
        if (query.minFileSize || query.maxFileSize) {
          if (!query.minFileSize) {
            exactSearchStrs.push(`文件小于${query.maxFileSize}MB`)
          } else if (!query.maxFileSize) {
            exactSearchStrs.push(`文件大于${query.minFileSize}MB`)
          } else {
            exactSearchStrs.push(`文件大小为${query.minFileSize}-${query.maxFileSize}MB`)
          }
        }
        if (query.minAuditTime && query.maxAuditTime) {
          exactSearchStrs.push(`上传时间在${query.minAuditTime}-${query.maxAuditTime}之间`)
        }
        if (query.minPageTotal || query.maxPageTotal) {
          if (!query.minPageTotal) {
            exactSearchStrs.push(`文件页数小于${query.maxPageTotal}页`)
          } else if (!query.maxPageTotal) {
            exactSearchStrs.push(`文件页数大于${query.minPageTotal}页`)
          } else {
            exactSearchStrs.push(`文件页数在${query.minPageTotal}-${query.maxPageTotal}之间`)
          }
        }

        if (exactSearchStrs && exactSearchStrs.length) {
          str += exactSearchStrs.join('/') + ' '
        }

        if (arr && arr.length) {
          str += arr.join('的')
        }
        if (activityType) {
          str += activityType
        }
        if (planType) {
          str += planType;
        }

        let freeTypeArr = []
        let freeTypeSplit = (freeTypes || '').split(',')
        if (freeTypeSplit.includes(freeTypeEnum.free + '')) {
          freeTypeArr.push('免费方案')
        }
        if (freeTypeSplit.includes(freeTypeEnum.vipFree + '')) {
          freeTypeArr.push('会员免费')
        }
        if (freeTypeSplit.includes(freeTypeEnum.needPay + '')) {
          freeTypeArr.push('会员折扣')
        }

        if (freeTypeArr.length === 0 || freeTypeArr.length === 3) {
          str += '(全部方案)'
        } else {
          str += `(${freeTypeArr.join(',')})`
        }

        const queryString = objToQueryString(query)
        const url = 'https://m.001ppt.com/home?' + queryString
        const redirectId = redirectV2Api.create(url)
        const finalUrl = `https://www.001ppt.com/redirect?id=${redirectId}`
        copyToClipBoard(`${str} ${finalUrl}`);
        this.$toast.success('复制成功')
      } finally {
        this.$loading.close()
      }
    },


    toggleSearch() {
      this.$refs.homePlanSearch.toggleSearch()
    },

    hideSearch() {
      this.$refs.homePlanSearch.hide()
    },


    onScroll() {
      // if (this.currTab !== 'plan') {
      //   return
      // }
      const scrollTop = document.querySelector('.home-plans').scrollTop
      this.$store.commit('scrollPosition/SET_HOME_SCROLL', {plan: scrollTop})

      if (!this.scrolling) {
        this.scrolling = true
        setTimeout(() => {
          this.scrolling = false
        }, 1000)
      }
      const bottom = document.querySelector('#planSticky').getBoundingClientRect().bottom
      const items = document.getElementsByClassName('home-plan-item')
      let index = 1
      for (const item of items) {
        if (item.getBoundingClientRect().top < bottom) {
          index++
        }
      }
      this.currCardIndex = index
    },


    toTop() {
      document.querySelector('.home-plans').scrollTo({top: 0, behavior: 'smooth'})
    },


    onDropdownItemOpen() {
      const $planStickyRect = document.querySelector('#planSticky').getBoundingClientRect()
      if ($planStickyRect.top > this.headerHeight + 20) {
        let top = 0
        top += document.querySelector('.home-banner').getBoundingClientRect().height
        top += document.querySelector('.home-quick-link').getBoundingClientRect().height

        document.querySelector('.home-plans').scrollTo({top, behavior: 'smooth'})
      }

    },


    onSearchBrank(brank) {
      this.query.brank = brank
      this.onChangeQuery()
    }

  }
}
</script>

<style scoped lang="less">
@import "./home-list";


.free-types .cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 16px;
}

.free-types-btn {
  width: 70vw;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  margin: 12px auto;
}

.free-types-btn.confirm {
  background: #151D36;
  color: #fff;
}

.category-item-name {
  padding-top: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-item-name .check-icon {
  padding-right: 16px;
  display: flex;
  align-items: center;
  transform: scale(.8);
}

.sub-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sub-category-item {
  flex-shrink: 0;
  margin-right: 8px;
  padding: 4px 8px;
  background: #f2f2f2;
  border-radius: 100px;
  font-size: 12px;
  margin-top: 8px;

  &.selected {
    background: #151D36;
    color: #fff;
  }
}

.filter-panel {
  flex-grow: 1;
  overflow: auto;
  padding: 0 12px 12px 12px;
  height: 238px;
}

.dropdown-title {
  display: flex;
}

.dropdown-title.dot:after {
  content: '';
  display: block;
  height: 6px;
  width: 6px;
  background: #e44c4c;
  border-radius: 100px;
  transform: translateY(4px);
}

.category-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 0;
}

.category-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 100px;
}

.category-btn.confirm {
  background: #151D36;
  color: #fff;
  width: 60vw;
}

.category-btn.cancel {
  background: #f4f4f4;
  width: 30vw;
}

.pager-close {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  border-radius: 100px;
  top: -5px;
  right: -5px;
}

</style>


----------------------------------------------------


<style lang="less">
.home-plans .van-sidebar-item {
  padding: 0;
  font-size: 12px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.home-plans .van-sidebar-item--select::before {
  background: #151D36;
}

.home-plans .van-info--dot {
  transform: translateY(16px) translateX(8px);
}

//.home-plans .van-dropdown-menu__bar {
//  border-bottom: none;
//}


.home-plans .van-dropdown-item__content {
  position: absolute;
}

.home-plans .ai-keywords {
  display: flex;
  align-items: center;
  background: #ffeee7;
  border-radius: 100px;
  border: 1px solid #ff4906;
  padding: 4px 4px 4px 4px;
  margin: 0 27px;
  color: #524f4e;
}

.home-plans .ai-keywords-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

.home-plans .ai-keyword-item {
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 0 2px 0 2px;
}

.home-plans .ai-keyword-item .checkbox {
  height: 12px;
  width: 12px;
  margin-right: 4px;
  border: 1px solid #a39c98;
  background: #fff;
}

.home-plans .ai-keyword-item .checkbox.checked {
  background: #151D36;
  color: #fff;
  border-color: #151D36;
}

.home-plans .ai-icon {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.home-plans .ref-plan-lists {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
  margin: 12px 27px 0 27px;
}

.home-plans .ref-plan-lists .ai-icon {
  height: 18px;
  width: 18px;
  margin: 0 6px;
}

.home-plans .ref-plan-list-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 54px);
  padding: 2px 0 0 0;
}

.home-plans .ref-plan-list-item {
  background: #ffeee7;
  border-radius: 100px;
  border: 1px solid #ff4906;
  padding: 5px 8px;
  margin: 3px 6px 3px 0;
  font-size: 10px;
  color: #524f4e;
}

.home-plans .ai-keywords-clear{
  color: #ff4906;
  font-size: 14px;
  margin-right: 4px;
  transform: translateY(2px);
}

.home-plans .ai-keywords-any-checked{
  background: rgb(255,167,135);
  border-color: rgb(255,167,135);
  color: #fff;
}

</style>
