<template>
  <transition name="van-fade">
    <div class="login-dialog" v-if="loginDialogVisible">
      <div class="login-shade" @click="closeDialog"></div>
      <img src="https://cdn.001ppt.cn/pc2/static/imgs/jpg/login-logo.png" class="login-logo" alt="">
      <div class="login-slogan">让超级个体，先快速找到好资料</div>
      <div class="login-main">
        <div class="login-type-switch" v-if="isWxEnv">
          <img class="login-type-icon" @click="loginType='wx'" v-if="loginType==='mobile'"
               src="https://cdn.001ppt.cn/pc2/login/login-badge-wx.svg" alt="">
          <img class="login-type-icon" @click="loginType='mobile'" v-if="loginType==='wx'"
               src="https://cdn.001ppt.cn/pc2/login/login-badge-mobile.svg" alt="">
        </div>
        <div class="login-type-switch" v-else style="font-size: 12px;padding: 12px 12px 0 0;font-weight: bolder;color: #999" @click="closeDialog">
          <i class="iconfont icon-close"></i>
        </div>

        <div class="login-title">10秒登录，即看即下</div>

        <div class="login-form" v-if="loginType==='mobile' || isLoginSetMobile">
          <div class="login-input">
            <div class="area-code gray-block" type="text">+86</div>
            <input v-model="form.mobile" type="text" class="gray-block mobile-input"
                   placeholder="请输入手机号码">
          </div>
          <div class="login-input gray-block verify-code-box" style="margin-top: 14px">
            <input v-model="form.code" style="width: 155px" type="text" class="gray-block" placeholder="请输入6位验证码"
                   maxlength="6">
            <div class="verify-code-btn gray-block" @click="sendCode">{{ verifyBtnText }}</div>
          </div>
          <div class="deal">
            我已阅读并同意
            <div class="link" @click="go('/about/agreement')">《用户协议》</div>
            和
            <div class="link" @click="go('/about/agreement')">《隐私政策》</div>
          </div>

          <div class="login-btn" @click="login" v-if="!isLoginSetMobile">进入灵感严选</div>
          <div class="login-btn" @click="setPhone" v-else>进入灵感严选</div>
        </div>

        <div class="login-form" v-else-if="loginType === 'wx'">
          <div class="wx-login-btn" @click="goWx">
            <img src="https://cdn.001ppt.cn/h5/static/image/wx-normal.svg" alt="">
          </div>
        </div>

        <div class="login-tip">
<!--          为提高账号安全性暂不支持固定密码登录-->
        </div>
        <div class="award">
          <div>🎁<span>享{{
              successAward.freeCase
            }}{{ $store.state.isFreeCaseUnitDay ? '份/日' : '份' }}免费方案下载</span></div>
          <div><span>{{ $store.state.isFreeCaseUnitDay ? '首次登录' : '' }}还送</span>💰<span>{{
              yanzhiFilter(successAward.yanzhi)
            }}严值奖励(¥{{ moneyFilter(successAward.yanzhi / 10) }})</span></div>
        </div>

      </div>

      <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/login-credit-2.png?ts=123" class="login-intro" alt="">
    </div>
  </transition>
</template>

<script>
import {login, user} from '@/api'
import {mapGetters, mapMutations} from 'vuex';
import {taskV2Api} from "../api/v2/taskV2Api";
import {vipRightV2Api} from "../api/v2/vipRightV2Api";

export default {
  data() {
    return {
      loginType: 'wx',  // wx, mobile
      verifyBtnText: '获取验证码',
      inCountDown: false,
      selectedOption: {label: '+86', value: ''},
      options: [
        {label: '+86', value: '+86'}
      ],
      optionsVisible: false,
      form: {
        mobile: '',
        code: ''
      },
      successAward: {
        yanzhi: 50,
        freeCase: 0
      },
      isWxEnv: false
    }
  },

  computed: {
    ...mapGetters(['loginDialogVisible', 'userInfo', 'isLoginSetMobile'])
  },

  created() {
    if (this.getBrowser() !== 'wechat') {
      this.loginType = 'mobile';
    }
    this.isWxEnv = this.getBrowser() === 'wechat'
    this.loadFirstLoginSuccessAward();
  },

  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_USERINFO', 'SET_MEMBER']),
    // 前往指定页面
    go(path) {
      this.$router.push({path});
    },

    async loadFirstLoginSuccessAward() {
      const loginTask = await taskV2Api.findFirstLoginTask()
      this.successAward.yanzhi = loginTask.value
      const registerVip = await vipRightV2Api.findById(3)
      this.successAward.freeCase = registerVip.freeCase

      // const {data, code} = await user.getFirstLoginSuccessAward();
      // if (code === 1) {
      //   this.successAward.yanzhi = data.yanzhi;
      //   this.successAward.freeCase = data.freeCase;
      //   this.$forceUpdate();
      // }
    },

    goWx() {
      this.$router.push({
        path: '/wx',
        query: {
          redirect: this.$route.path
        }
      })
    },

    closeDialog() {
      if (!this.$route.meta.$from) {
        this.$router.push('/home');
      }
      this.$store.dispatch('hideLoginDialog');
    },

    async hide(ignoreCheck) {
      this.$store.state.isLoginSetMobile = false;
      if (ignoreCheck !== false && !Object.keys(this.userInfo).length) {
        if (this.$route.meta.auth || this.$route.path === '/') {
          this.$router.replace('/home');
        }
      }

      if (!Object.keys(this.userInfo).length || !this.userInfo.mobile || !this.userInfo.mobile.length) {
        let to = this.$store.state.to || '/home';
        await user.logout();
        this.SET_TOKEN('');
        this.SET_USERINFO({});

        if (to.startsWith('https')) {
          to = to.replace(window.location.origin, '')
        }
        this.$router.replace(to).then();
      }

      this.$store.commit('SET_LOGIN_REDIRECT', '')
      this.$store.dispatch('hideLoginDialog').then();
    },

    // 发送验证码
    async sendCode() {
      if (this.inCountDown) {
        return;
      }
      const reg = /1[3456789]\d{9}/g;
      if (!reg.test(this.form.mobile)) {
        return this.$toast.info('请输入正确格式的手机号');
      }
      this.$toast.success('发送成功');
      try {
        user.getPhoneCode(this.form.mobile).then();
        this.inCountDown = true;
        let seconds = 60;
        const interval = setInterval(() => {
          this.verifyBtnText = `${seconds}s后重试`;
          seconds--;
          if (seconds <= 0) {
            clearInterval(interval);
            this.inCountDown = false;
            this.verifyBtnText = '获取验证码';
          }
        }, 1000);
      } catch (e) {
        this.$toast.info('获取验证码失败');
      }
    },

    // 登录
    async login() {
      if (!this.form.mobile) {
        return this.$toast.info('请输入手机号');
      }
      if (!this.form.code) {
        return this.$toast.info('请输入验证码');
      }

      try {
        const res = await login({
          loginType: 'MOBILE',
          mobile: this.form.mobile,
          validateCode: this.form.code,
          shareUserId: localStorage.shareUserId ? localStorage.shareUserId : '',
          sharePlanId: localStorage.sharePlanId || ''
        });
        //const res = await login({loginType: 'COMMON', account: '17360155211',password: '123456'});
        if (res.code !== 1) {
          return this.$toast.info(res.msg || '失败');
        }
        this.SET_TOKEN(res.data.token);
        this.SET_USERINFO(res.data);
        const prom1 = this.$store.dispatch('getWallet');
        const prom3 = this.$store.dispatch('getMemberInfo');
        await Promise.all([prom1, prom3]);
        this.$toast.success('登录成功');
        if (this.$store.state.to && this.$store.state.to.length) {
          this.$router.push(this.$store.state.to);
        }
        // 清除分享人
        localStorage.shareUserId = '';
        localStorage.sharePlanId = ''
        this.hide(true);
        window.location.reload();
      } catch (e) {
        this.$toast('登录失败');
      }
    },

    async setPhone() {
      if (!this.form.mobile) {
        return this.$toast.info('请输入手机号');
      }
      if (!this.form.code) {
        return this.$toast.info('请输入验证码');
      }

      try {
        const res = await user.bindMobile({mobile: this.form.mobile, validateCode: this.form.code});
        //const res = await login({loginType: 'COMMON', account: '17360155211',password: '123456'});
        if (res.code != 1) {
          return this.$toast.error('失败');
        }
        const userRes = await user.userInfo();
        this.SET_USERINFO(userRes.data);
        this.$toast.success('绑定成功');
        if (this.$store.state.to && this.$store.state.to.length) {
          this.$router.push(this.$store.state.to);
        }
        // 清除分享人
        this.hide(true);
      } catch (e) {
        console.error(e)
        this.$toast.error('操作失败');
      }

    },

    //浏览器
    getBrowser() {
      const ua = window.navigator.userAgent.toLowerCase();
      let result = '';
      if (ua.match(/MicroMessenger/i) === null) {
        result = 'h5';
      } else {
        result = 'wechat';
      }
      // console.log(`当前使用的浏览器为：${result}`);
      return result;
    },
  }
}
</script>

<style lang="less" scoped>
.login-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-dialog .login-shade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #151D36;
  opacity: .8;
  z-index: -1;
}

.login-dialog .login-logo {
  width: 168px;
  height: 35px
}

.login-dialog .login-slogan {
  width: 287px;
  height: 19px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  vertical-align: center;
  margin: 14px 0 24px;
}

.login-dialog .login-main {
  width: 320px;
  height: 358px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.login-dialog .login-title {
  padding-top: 14px;
  height: 25px;
  position: relative;
  text-align: center;
}

.login-dialog .login-title::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background: #151d36;
  border-radius: 100px;
  bottom: -4px;
}

.login-dialog .login-intro {
  width: 210px;
  height: 77px;
  margin-top: 16px;
  margin-bottom: 40px;
}

.login-dialog .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  padding-top: 30px;
  height: 204px;
}

.login-dialog .login-input {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.login-dialog .gray-block {
  height: 32px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  flex-shrink: 0;
}

.login-dialog input.gray-block {
  padding: 0 8px;
  color: #151d36;
}

.login-dialog input.gray-block::placeholder {
  color: #1e151d36;
}

.area-code {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  color: #1e151d36;
  margin-right: 11px;
}

.mobile-input{
  width: 200px;
}

.verify-code-box{
  width: 270px;
}

.login-dialog .verify-code-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 116px;
  color: #151d36;
  position: relative;
}

.login-dialog .verify-code-btn::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) scaleX(.5);
  width: 1px;
  height: 15px;
  background: #999;
  border-radius: 100px;
}

.login-dialog .deal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-top: 17px;
}

.login-dialog .deal .link {
  color: #0e8ffb;
}

.login-dialog .login-btn {
  width: 260px;
  height: 40px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(21, 29, 54, 1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  margin-top: 22px;
}

.login-dialog .login-tip {
  margin-top: 36px;
  text-align: center;
}

.login-dialog .award {
  margin-top: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-dialog .award span {
  color: #64151d36;
  font-size: 12px;
}

.login-dialog .wx-login-btn {
  padding-top: 30px;
}

.login-dialog .wx-login-btn .deal {
  padding-top: 0;
}

.login-dialog .wx-login-btn img {
  height: 120px;
  width: 120px;
}

.login-dialog .login-type-switch {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  z-index: 1;
}

.login-dialog .login-type-icon {
  height: 45px;
  width: 45px;
}
</style>
