<template>
  <div class="home-rank-sub-tab">

    <div class="home-rank-sub-tab-item" :class="{active:tab==='collect'}" @click="switchTab('collect')">
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-shoucang-active.svg" v-if="tab === 'collect'" class="home-rank-sub-tab-item-img"/>
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-shoucang.svg" v-else class="home-rank-sub-tab-item-img"/>
      <div class="home-rank-sub-tab-item-text">收藏榜</div>
    </div>
    <div class="home-rank-sub-tab-item" :class="{active:tab==='planType'}" @click="switchTab('planType')">
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-fenlei-active.svg" v-if="tab === 'planType'" class="home-rank-sub-tab-item-img"/>
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-fenlei.svg" v-else class="home-rank-sub-tab-item-img"/>
      <div class="home-rank-sub-tab-item-text">分类榜</div>
    </div>
    <div class="home-rank-sub-tab-item" :class="{active:tab==='hotScore'}" @click="switchTab('hotScore')">
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-biaosheng-active.svg" v-if="tab === 'hotScore'" class="home-rank-sub-tab-item-img"/>
      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-biaosheng.svg" v-else class="home-rank-sub-tab-item-img"/>
      <div class="home-rank-sub-tab-item-text">飙升榜</div>
    </div>
<!--    <div class="home-rank-sub-tab-item" :class="{active:tab==='recommended'}" @click="switchTab('recommended')">-->
<!--      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-jingpin-active.svg" v-if="tab === 'recommended'" class="home-rank-sub-tab-item-img"/>-->
<!--      <img src="https://cdn.001ppt.cn/h5/home-rank/home-rank-jingpin.svg" v-else class="home-rank-sub-tab-item-img"/>-->
<!--      <div class="home-rank-sub-tab-item-text">新品榜</div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  props: ['tab'],

  methods: {
    switchTab(tab) {
      this.$emit('update:tab', tab)
      this.$emit('change', tab)
    }
  }

}
</script>

<style scoped lang="less">

.home-rank-sub-tab {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  height: 100px;
}

.home-rank-sub-tab-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-rank-sub-tab-item-img {
  height: 36px;
  width: 36px;
  margin-bottom: 8px;
}

.home-rank-sub-tab-item-text {
  font-size: 12px;
  color: #999;
}

.home-rank-sub-tab-item.active .home-rank-sub-tab-item-text {
  color: #151D36;
}

</style>
